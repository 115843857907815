import { GetAppOutlined } from "@mui/icons-material";
import React from "react";
import { LoadingIndicator, Buttons, PseudoLink2 } from "../../toolympus/components/primitives";
import { DownloadFileData } from "../../toolympus/hooks/useDownloadFile";

interface GetFileControlProps {
  label: string;
  download: () => void;
  isDownloading: boolean;
}

export const GetFileControl = (props: GetFileControlProps) => {
  return props.isDownloading
    ? <LoadingIndicator sizeVariant="s" />
    : (
      <Buttons gap="small" onClick={() => !props.isDownloading && props.download()}>
        <GetAppOutlined color="primary" fontSize="inherit" />
        <PseudoLink2>
          {props.label}
        </PseudoLink2>
      </Buttons>
    );
}



export const GetMultifileDisplay = (props: { fileIds: string | undefined, apiPathPrefix: string, downloader: DownloadFileData }) => {
  const fileIds = (props.fileIds || "").split("\n").map(s => s.trim()).filter(s => !!s)
  return <>
    {fileIds
      .map((fid,idx) => (
        <GetFileControl
          key={fid}
          label={`файл ${idx+1}`}
          isDownloading={props.downloader.isLoading}
          download={() => props.downloader.download({ apiPath: `${props.apiPathPrefix}/${fid}`})}
          />
      ))}
  </> 
}
