import { useSmartList } from '../../toolympus/hooks/useSmartList';
import { useSingleSchema } from '../../toolympus/hooks/useSchema';
import { schemaWithConfigurableFields, useApplicationsConfiguration } from './useApplicationsConfiguration';
import { useMemo } from 'react';
import { useEditItem2 } from '../../toolympus/api/useNewItem';
import { ApplicationRecord } from './typings';

export const ApiPath = "/api/applications";
export const ApplicationsApiPath = `${ApiPath}/manage`


export const useApplicationsList = () => {
  const { schema: schemaBase } = useSingleSchema(`${ApplicationsApiPath}/uiconfig`)
  const data = useSmartList<ApplicationRecord>(ApplicationsApiPath, {
    newRecordDefault: {},
    schema: schemaBase,
  });

  const config = useApplicationsConfiguration();

  const schema = useMemo(
    () => schemaWithConfigurableFields(
      schemaBase,
      config.data.fields,
      { cropTitles: true }),
    [schemaBase, config.data]);

  const review = useEditItem2<ApplicationRecord>({
    getApiPath: r => `${ApplicationsApiPath}/${r._id}`,
  });

  const dataX = useMemo(() => {
    return data.data.map(s => {
      const copy = { ...s } as any;
      config.data.fields.forEach(f => {
        copy[f._id] = s.info[f._id];
      });
      return copy;
    });
  }, [data.data, config.data]);

  return {
    ...data,
    data: dataX,
    config,
    schema,
    review,
  }
}


