import React, { ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { EditItemProps } from '../../toolympus/api/useNewItem';
import { Buttons, Dialog, FormGrid, LoadingIndicator, useCopyText } from '../../toolympus/components/primitives';
import { FormControlsForFields, MaybeFieldDefinition } from '../../toolympus/components/schemed';
import { Schema } from '../../toolympus/hooks/useSchema';
import { IconButton, Typography } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import { useDownloadFile } from '../../toolympus/hooks/useDownloadFile';
import { FormControlBaseProps } from '../../toolympus/components/schemed/FormControlProps';
import { ApplicationConfig, ApplicationRecord } from './typings';
import { useApplicationControls } from './useApplicationsConfiguration';
import { GetMultifileDisplay } from './ApplicationsFiles';

const AvatarSizes = {
  large: 150,
  medium: 50,
  small: 24,
}

export const Avatar = styled.img<{ size?: "large" | "medium" | "small" }>`
  width: ${props => AvatarSizes[props.size || "large"]}px;
  height: ${props => AvatarSizes[props.size || "large"]}px;
  border-radius: ${props => AvatarSizes[props.size || "large"] / 2}px;
  box-shadow: 0 0 ${props => !props.size || props.size === "large" ? 10 : 8}px -5px ${props => props.theme.palette.grey[800]};
`;




interface Props {
  data: EditItemProps<ApplicationRecord>;
  schema: Schema;
  profileConfiguration: ApplicationConfig;
}

interface DownloadFileProps {
  download: (fileId: string) => void;
  isDownloading: boolean;
  fileId: string | null | undefined;
  label: ReactNode;
}

export const DownloadFileControl = (props: DownloadFileProps) => {
  return <div>
    <Typography variant="caption" color="textSecondary" component="p">{props.label}</Typography>
    {!props.fileId
      ? <Typography>-</Typography>
      : props.isDownloading
        ? <LoadingIndicator sizeVariant="s" color="primary" />
        : <IconButton size="small" color="primary" onClick={() => props.download(props.fileId || "")}>
      <GetAppOutlined />
    </IconButton>}
  </div>
}

export const ApplicationReviewDialog = (props: Props) => {
  const { data } = props;
  const { schema: schemaFields, fields, fileFields, multifileFields } = useApplicationControls(props.profileConfiguration);
  const copyText = useCopyText();

  const downloadFile = useDownloadFile("");

  const fullFields: MaybeFieldDefinition[] = useMemo(() => {

    const FileControl = (p: FormControlBaseProps) => {
      return (
        <DownloadFileControl
          fileId={p.row[p.field]}
          download={fileId => !downloadFile.isLoading && downloadFile.download({ apiPath: `/api/applications/document/${fileId}` })}
          isDownloading={downloadFile.isLoading}
          label={(p.schema || {}).label}
          />
      )
    }

    const MultifileControl = (p: FormControlBaseProps) => {
      return <Buttons vertical>
        <Typography variant="caption" color="textSecondary">{p.schema?.label}</Typography>
        <Buttons>
          <GetMultifileDisplay
            apiPathPrefix="/api/applications/document"
            downloader={downloadFile}
            fileIds={p.row[p.field]}
            />
        </Buttons>
      </Buttons>
    }

    return fields.map(f => {
      const [fid,options] = f || [];
      if(!!fid && fileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: FileControl,
          }
        ];
      } else if(!!fid && multifileFields.includes(fid)) {
        return [
          fid,
          {
            ...(options || {}),
            control: MultifileControl,
          }
        ];
      } else {
        return f;
      }
    });
  }, [fields, fileFields, multifileFields, downloadFile]);


  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Заявка">
      {!!data.item &&
        <FormGrid columns="1fr">
          <FormGrid noMargin columns="1fr 150px">
            <FormGrid noMargin columns="1fr">
              <FormControlsForFields
                schema={props.schema}
                data={data.item}
                onChange={() => {}}
                fields={[
                  ["email", {
                    label: "E-mail",
                    readOnly: true,
                    controlProps: {
                      onClick: (e: any) => { e.stopPropagation(); e.preventDefault(); copyText(""); },
                    },
                  }],
                ]}
                />

                

            </FormGrid>

          </FormGrid>
          

          <FormControlsForFields
            schema={schemaFields}
            readOnly
            data={data.item}
            onChange={() => {}}
            fields={fullFields}
            />
        </FormGrid>}
        
    </Dialog>
  );
}
